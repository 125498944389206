@import "../../../assets/scss/abstracts/variables";
@import "../../../assets/scss/abstracts/mixins";
.hero {
  @extend %container;
  display: grid;
  min-height: calc(100vh - 16px);
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 60px;
    justify-content: center;
  }
  .headers {
    margin-bottom: 60px;
    @media (max-width: $phone) {
      margin-bottom: 32px;
    }
    h1 {
      font-size: 80px;
      font-weight: 400;
      margin-bottom: 20px;
      @media (max-width: $phone) {
        font-size: 42px;
        margin-bottom: 10px;
      }
    }
    h3 {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 60px;
      @media (max-width: $phone) {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    p {
      font-size: 18px;
      white-space: pre-wrap;
      @media (max-width: $phone) {
        font-size: 14px;
      }
    }
    @media (max-width: $tablet) {
      text-align: center;
    }
  }
  .actions {
    width: 370px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: $tablet) {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
    }
    @media (max-width: $phone) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 180px;
      margin: 0 auto;
    }

    .learnMore {
      display: flex;
      justify-content: center;
      gap: 4px;
    }
  }
  .animation {
    place-self: center;
  }
}
