@import "../../../assets/scss/abstracts/variables";
.background {
  background: $darkish;
  position: sticky;
  top: 0;
  z-index: 10;
}
.container {
  max-width: 1200px;
  padding: 8px 15px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  @media (max-width: $tablet) {
    max-width: 100vw;
    padding: 0 30px;
  }
}
.menu{
  cursor: pointer;
}
.logo {
  width: 208px;
  img {
    width: 100%;
  }
  @media (max-width: $tablet) {
    width: 136px;
  }
}
.actions {
  display: flex;
  gap: 8px;
  justify-self: end;
}

.nav__container {
  background: #000;
  position: fixed;
  height: 100%;
  width: 420px;
  top: 0;
  padding: 24px;
  z-index: 12;

  @media (max-width: $tablet) {
    width: 240px;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .nav__heading {
    color: white;
    border-bottom: 2px solid white;
    padding-bottom: 23px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .nav_button {
    margin-bottom: 20px;
  }
}

.walletContainer {
  position: relative;
}

.wallet {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid white;
  padding: 0 16px;
  border-radius: 2px;
  height: 44px;
  cursor: pointer;
  svg {
    position: relative;
    width: 24px;
    height: 24px;
    top: 2px;
  }
  span {
    color: white;
  }
}

.walletMenu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background: $dark2;
  border: 1px solid white;
  border-radius: 4px;
  padding: 8px;
  min-width: 150px;
  z-index: 11;
}

.disconnectButton {
  width: 100%;
  background: none;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }
}
