@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/mixins";

.admin__container {
  @extend %container;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: 200px;
  }
}

.form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    text-align: center;
  }

  button {
    margin-top: 20px;
  }
}

.cell { 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.icon_add {
  color: $primary;
}