@import "../../../assets/scss/abstracts/variables";
@import "../../../assets/scss/abstracts/mixins";

.membership__top__image {
  display: none;

  @media (max-width: $phone) {
    display: block;
    margin-bottom: 40px;
  }
}
.membership__wrapper {
  scroll-margin-top: 80px;
  position: relative;
}
.membership__top {
  background: $backgroundGrad;
  height: 294px;
  @media (max-width: $tablet) {
    background: $backgroundGrad1;
    height: 483.8px;
  }
  @media (max-width: $phone) {
    height: 521px;
  }
}
.membership__bottom {
  background: $dark;
  height: 976px;

  @media (max-width: $tablet) {
    height: 1695px;
  }
  @media (max-width: $phone) {
    height: 1156px;
  }
}
.membership__content {
  @extend %container;

  position: absolute;
  top: 0;
  transform: translateX(50%);
  right: 50%;

  @media (max-width: $phone) {
    top: 35px;
  }
}
.membership__content__header {
  @media (max-width: $tablet) {
    text-align: center;
  }
}
.membership__title {
  color: $dark;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 10px;

  @media (max-width: $tablet) {
    font-size: 31px;
  }
  @media (max-width: $phone) {
    font-size: 28px;
    margin-bottom: 5px;
  }
}
.membership__subtitle {
  background: $canvagrad;
  font-size: 32px;
  background-clip: text;
  color: transparent;

  @media (max-width: $tablet) {
    font-size: 27px;
  }
  @media (max-width: $phone) {
    font-size: 24px;
  }
}
.membership__benefits {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 131px;
  gap: 30px;

  @media (max-width: $tablet) {
    display: block;
  }

  @media (max-width: $phone) {
    margin-bottom: 40px;
  }
}
.membership__benefit {
  background-color: $light;
  color: $dark2;
  width: 370px;
  height: 327px;
  padding: 53px 40px 58px 97px;

  &:first-of-type {
    background-color: $dark7;
    color: $light;
  }

  @media (max-width: $tablet) {
    width: 650px;
    height: 220px;
    margin-bottom: 25px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:first-of-type {
      background-color: $dark;
      color: $light;
    }
  }
  @media (max-width: $phone) {
    width: 315px;
    height: 246px;
    padding-top: 15px;
    padding-left: 50px;
  }
}
.membership__benefit__title {
  font-size: 24px;
  font-weight: 800;
  line-height: 38px;
  margin-bottom: 20px;

  @media (max-width: $tablet) {
    font-size: 20px;
    width: 501px;
  }
  @media (max-width: $phone) {
    width: 208px;
    line-height: 28px;
    margin-bottom: 15px;
  }
}
.membership__benefit__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: $tablet) {
    font-size: 14px;
    width: 501px;
  }
  @media (max-width: $phone) {
    width: 208px;
    line-height: 20px;
  }
}
.membership__status__container {
  display: flex;
  margin: 0 auto;
  width: 970px;
  height: 584px;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: $tablet) {
    display: block;
    width: 100%;
  }
}
.membership__status {
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }
}
.membership__status__title {
  color: $light;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0;

  @media (max-width: $phone) {
    font-size: 28px;
    margin-bottom: 5px;
  }
}
.membership__status__subtitle {
  background: $canvagrad;
  font-size: 32px;
  background-clip: text;
  color: transparent;

  @media (max-width: $phone) {
    font-size: 24px;
  }
}
.membership__status__image {
  width: 470px;
  height: 490px;

  @media (max-width: $tablet) {
    margin-bottom: 40px;
  }

  @media (max-width: $phone) {
    display: none;
  }
}
.membership__status__content {
  border-left: 2px solid $canvaright;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 470px;

  @media (max-width: $tablet) {
    margin: 0 auto;
    padding-left: 53px;
  }

  @media (max-width: $phone) {
    width: 100%;
    padding-left: 40px;
  }
}
.membership__status__box {
  color: $light;
  @media (max-width: $tablet) {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: $phone) {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.membership__status__box__title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;

  @media (max-width: $tablet) {
    font-size: 20px;
  }

  @media (max-width: $phone) {
    margin-bottom: 12px;
  }
}
.membership__status__box__text {
  font-size: 16px;
  font-weight: 400;

  @media (max-width: $tablet) {
    font-size: 14px;
  }

  @media (max-width: $phone) {
    line-height: 20px;
  }
}
.membership__tagline {
  display: none;

  @media (max-width: $tablet) {
    display: block;
    background: $canvagrad;
    font-size: 24px;
    background-clip: text;
    color: transparent;
    text-align: center;
    margin-top: 60px;
  }

  @media (max-width: $phone) {
    font-size: 16px;
    margin-top: 30px;
  }
}
