@import "../abstracts/variables";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  text-size-adjust: 100%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
ul,
li,
img,
pre {
  margin: 0;
  padding: 0;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  outline: none;
  border: none;
}

a {
  outline: none;
  text-decoration: none;
}

input {
  outline: none;
}

img, video {
  max-width: 100%;
}
