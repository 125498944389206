@import "../../../assets/scss/abstracts/variables";
@import "../../../assets/scss/abstracts/mixins";

.faq__wrapper {
  background: $dark;
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (max-width: $tablet) {
    padding: 0;
  }

  @media (max-width: $phone) {
    width: 100%;
  }
}
.faq__container {
  @extend %container;

  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
  }

  @media (max-width: $phone) {
    flex-direction: column-reverse;
  }
}
.faq__image {
  max-width: 555px;

  @media (max-width: $tablet) {
    max-width: 470px;
  }

  @media (max-width: $phone) {
    margin-left: -30px;
    margin-right: -30px;
    padding-bottom: 40px;
  }
}
.faq__content {
  width: 534px;
  background: $dark1;
  padding: 50px 58px;

  @media (max-width: $tablet) {
    width: 650px;
    padding: 116px;
    background: $dark1;
  }

  @media (max-width: $phone) {
    background: $dark;
    width: 100%;
    padding: 40px 0 0;
  }
}
.faq__content__header {
  width: 376px;
  margin-bottom: 45px;

  @media (max-width: $tablet) {
    margin-bottom: 40px;
  }
  @media (max-width: $phone) {
    text-align: center;
    width: 100%;
  }
}
.faq__content__title {
  color: $light;
  font-weight: 700;
  font-size: 34px;
  line-height: 130%;
  margin-bottom: 10px;

  @media (max-width: $phone) {
    display: none;
  }
}
.faq__content__mobile__title {
  display: none;
  @media (max-width: $phone) {
    display: block;
    color: $light;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    padding-bottom: 10px;
  }
}
.faq__content__subtitle {
  background: $canvagrad;
  font-size: 32px;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  line-height: 130%;

  @media (max-width: $phone) {
    display: none;
  }
}
.faq__content__mobile__subtitle {
  display: none;
  @media (max-width: $phone) {
    display: block;
    background: $canvagrad;
    background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
  }
}
.faq__accordion {
  background: $dark;
  padding: 24px;
  border-left: 2px solid $canvaright;

  @media (max-width: $tablet) {
    background: $dark;
  }

  @media (max-width: $phone) {
    width: 100%;
    border: none;
    padding: 12px;
    margin: 80px auto;
  }
}
.faq__tagline {
  display: none;

  @media (max-width: $tablet) {
    display: block;
    background: $canvagrad;
    background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    margin-top: 64px;
  }
  @media (max-width: $phone) {
    margin: 40px 0;
    font-size: 18px;
  }
}
