@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/mixins";

.marketplace__container {
  @extend %container;
  padding-top: 37px;
  padding-bottom: 205px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $tablet) {
    padding-bottom: 175px;
  }
}
.marketplace__heading {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  @media (max-width: $tablet) {
    justify-content: start;
  }
}
.marketplace__img {
  width: 220px;
  height: 220px;
  margin-right: 26px;
  border-radius: 50%;
  overflow: hidden;
  background: black;

  @media (max-width: $tablet) {
    width: 170px;
    height: 170px;
  }

  @media (max-width: $phone) {
    width: 120px;
    height: 120px;
    margin-right: 10px;
  }
}
.marketplace__title {
  font-size: 36px;

  @media (max-width: $tablet) {
    font-size: 32px;
  }

  @media (max-width: $phone) {
    font-size: 24px;
  }
}
.marketplace__subtitle {
  background: $canvagrad;
  font-size: 18px;
  background-clip: text;
  color: transparent;

  @media (max-width: $tablet) {
    font-size: 27px;
  }

  @media (max-width: $phone) {
    font-size: 18px;
  }
}
.marketplace__text {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  max-width: 623px;
  @media (max-width: $tablet) {
    display: none;
  }
}
.marketplace__mobile__text {
  display: none;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
  max-width: 623px;

  p {
    margin-bottom: 12px;
  }
  @media (max-width: $tablet) {
    display: block;
  }
}
.nftcard__container {
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
}
.marketplace__nftcard {
  @extend %container;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  top: -125px;
  position: relative;

  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
  @media (max-width: $phone) {
    grid-template-columns: repeat(1, 1fr);
  }
}
