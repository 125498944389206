@import "../../../assets/scss/abstracts/variables";
.background {
  background: $dark1;
  color: $light;
}
.container {
  padding: 105px 15px 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  @media (max-width: $phone) {
    padding: 40px 30px 20px;
  }
}
.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 20px;
}
.subtitle {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 20px;
}
.form {
  margin: 0 0 40px;
  display: flex;
  gap: 20px;
  @media (max-width: $phone) {
    flex-direction: column;
    align-items: center;
  }
}
.email {
  min-width: 260px;
}
.submit {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.socials {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  gap: 30px;
  li {
    padding: 0;
  }
}
.menu {
  list-style: none;
  padding: 0;
  margin: 0 0 60px;
  display: flex;
  font-size: 12px;
  gap: 40px;
  row-gap: 10px;
  a {
    color: $light;
    font-weight: 600;
  }
  @media (max-width: $phone) {
    flex-wrap: wrap;
    width: 270px;
    justify-content: center;
    margin-bottom: 30px;
  }
  li {
    display: inline-flex;
    @media (max-width: $phone) {
      &:nth-child(4) {
        order: -1;
      }
      &:nth-child(1) {
        order: -1;
      }
    }
  }
}

.copyright {
  font-size: 12px;
}
