@import "../../assets/scss/abstracts/variables";

.nftCard__container {
  background: $nftCardBackground;
  color: $light;
  padding: 20px 20px 55px;
}
.nftCard__img {
  width: 100%;

  img {
    width: 100%;
  }
}
.nftCard__title {
  font-size: 18px;
  margin-top: 20px;
}
.nftCard__text {
  background: $canvagrad;
  background-clip: text;
  color: transparent;
}
.nftCard__details {
  margin-top: 20px;
  width: 100%;
  td:last-of-type {
    text-align: right;
  }
}
.nftCard__button_container {
  width: 100%;
  margin-top: 20px;
}
.nftCard__button {
  font-weight: 700;
  font-size: 18px;
  padding: 1rem;
}
