@import "../../assets/scss/abstracts/variables";
.accordion {
  color: $light;
  border-bottom: 2px solid $light;
  padding-bottom: 15px;
  padding-top: 10px;

  &:first-child {
    border-top: 2px solid $light;
  }
}
.accordion__header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 0.75rem;
  & span {
    margin-top: -0.5rem;
  }
}
.accordion__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $light2;
  padding-top: 15px;

  @media (max-width: $phone) {
    font-size: 12px;
  }
}
.accordion__title {
  font-size: 20px;
  width: 90%;

  @media (max-width: $phone) {
    font-size: 16px;
  }
}
