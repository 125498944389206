@import "../../../assets/scss/abstracts/variables";
@import "../../../assets/scss/abstracts/mixins";

.sunGowds {
  @extend %container;
  padding: 80px 0;
  @media (max-width: $tablet) {
    min-height: auto;
  }
  .title {
    text-align: center;
    h2 {
      font-size: 34px;
      font-weight: 700;
      line-height: 130%;
      @media (max-width: $tablet) {
        font-size: 30px;
      }
      @media (max-width: $phone) {
        font-size: 28px;
      }
    }
    .soon {
      background-image: $solargrad;
      background-clip: text;
      color: transparent;
    }
  }
  .presentation {
    margin: 40px 0;
    video {
      cursor: pointer;
    }
  }
  .benefits {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: $tablet) {
      grid-template-columns: 100%;
    }
  }
  .benefit {
    padding: 25px 50px;
    border-radius: 2px;
    position: relative;
    @media (max-width: $phone) {
      padding: 25px;
    }
    &:nth-child(odd) {
      background: $light1;
      &::before {
        content: "";
        width: 2px;
        position: absolute;
        top: 32px;
        bottom: 32px;
        background: $canvaright;
        left: 0;
      }
      @media (max-width: $phone) {
        box-shadow: 0 10px 30px 0 rgba(56, 29, 219, 0.2);
      }
    }
    h4 {
      margin-bottom: 20px;
      font-weight: 800;
      font-size: 20px;
      @media (max-width: $phone) {
        text-align: center;
      }
    }
  }
  .call {
    display: none;
    @media (max-width: $tablet) {
      display: block;
      text-align: center;
      background: $canvagrad;
      background-clip: text;
      color: transparent;
      font-size: 24px;
      font-weight: 700;
      margin-top: 40px;
      font-family: Raleway, sans-serif;
    }
    @media (max-width: $phone) {
      font-size: 18px;
    }
  }
}
