@import "../../assets/scss/abstracts/variables";

.container {
  .input {
    background: rgba($dark7, 0.2);
    border: 1px solid rgba($light, 0.2);
    font-family: "Raleway", sans-serif;
    padding: 15px 14px;
    width: 100%;
    color: inherit;
    &::placeholder {
      color: $dark7;
      opacity: 1;
    }
    &[aria-invalid="true"] {
      border: 1px solid $danger;
    }
  }

  .label {
    color: $dark;
    padding: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  p {
    color: $danger;
    font-size: 14px;
    margin-top: 5px;
  }
}
