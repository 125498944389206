@import '../../../../assets/scss/abstracts/variables';
@import '../../../../assets/scss/abstracts/mixins';

.card {
  padding: 25px;
  position: relative;
  text-align: center;
  transition: transform 0.2s ease-in-out;

  &::before {
    content: "";
    width: 2px;
    position: absolute;
    top: 16px;
    bottom: 16px;
    background: $primary;
    left: 0;
  }

  &:hover {
    transform: translateY(-5px);
  }

  .icon {
    font-size: 32px;
    color: $primary;
    display: block;
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    color: $light2;
  }

  .value {
    font-size: 1.5rem;
    font-weight: bold;
    color: $light;
  }
}
