@import "../../assets/scss/abstracts/variables";

.toggle {
  display: none;
  margin: 10px 0 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.popup {
  background: $light;
  border-radius: 12px;
  width: 600px;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
  padding: 24px;
}
.close {
  display: block;
  position: absolute;
  top: 26px;
  right: 16px;
  cursor: pointer;
  fill: $dark;
  stroke: $dark;
}
