@import '../../../assets/scss/abstracts/variables';
@import '../../../assets/scss/abstracts/mixins.scss';

.container {
  position: relative;
  padding: 4rem 0 0 0;
  text-align: center;
  scroll-margin-top: 60px;
}

.header {
  padding: 0 1rem;
  
  h2 {
    color: $dark;
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 3rem;

    @media (max-width: $tablet) {
      font-size: 30px;
    }
    @media (max-width: $phone) {
      font-size: 28px;
    }
  }
}

.gridContainer {
  width: 100%;
  padding: 2rem 0;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)),
  url('../../../assets/img/solarpanels.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
}
