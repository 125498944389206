@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/mixins";

.terms__container {
  @extend %container;
  margin: 60px auto;
  max-width: 900px;
  @media (max-width: $phone) {
    margin-bottom: 32px;
  }
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin: 30px 0;
    @media (max-width: $phone) {
      font-size: 18px;
      margin: 20px 0;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 40px 0 20px 0;
    @media (max-width: $phone) {
      font-size: 14px;
      margin: 20px 0;
    }
  }
  ol {
    list-style: decimal outside;
    padding-left: 20px;
  }
  li, p {
    margin: 20px 0;
  }
}
