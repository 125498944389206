@import "../../assets/scss/abstracts/variables";

.modalContent {
  color: $dark2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  button {
    display: flex;
    align-items: center;
    gap: 10px;
  
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}