%container{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  @media (max-width: $tablet) {
    max-width: 710px;
    padding: 0 30px;
  }
  @media (max-width: $phone) {
    max-width: 100vw;
  }
}