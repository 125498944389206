@import "../../assets/scss/abstracts/variables";
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: $primary;
  color: $light;
  font-size: 1rem;
  cursor: pointer;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border-radius: 2px;
}
.icon {
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  min-width: 50px;
  min-height: 50px;
}
.secondary {
  background: $light1;
  color: $dark2;
}
.tertiary {
  background: $light;
  color: $dark;
}
.disabled {
  background: $light2;
  color: $dark7;
  cursor: not-allowed;
}
